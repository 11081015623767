import React, { useState } from "react";
import styles from "../../styles/Auth.module.css";
import { useNavigate } from "react-router-dom";

const PasswordRecovery: React.FC = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword ] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword ] = useState<boolean>(false)
  const [otpMessage, setOtpMessage] = useState<string>("")
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");

  const validateEmail = (email: string): boolean => {
    return /\S+@\S+\.\S+/.test(email);
  };
  // const backendURL = 'http://localhost:5000';
  const backendURL = 'https://totle-webisite-backend.onrender.com';

  const invalidEmailMessages  = [
    "That doesn’t look right. Double-check your email—maybe your keyboard slipped.",
    "Invalid email address. If that was a creative attempt, I’m impressed—but let’s stick to standard email formats.",
    "Oops, we can’t send anything to that. Double-check your email address before we both get embarrassed.",
    "Email address invalid. Let’s give it another shot—this time, with fewer typos.",
    "Hmm, that doesn’t look like an email address. Maybe double-check before we blame autocorrect?",
    "Invalid email address. Let’s pretend that was a practice run and go for the real thing this time.",
    "Hmm, your email seems to be missing some essentials. Maybe an @ symbol or, you know, logic?",
    "We’re unable to process this email address. Please confirm it’s correctly formatted and try again."
  ]
  const failedOtpMessages = [
    "Sending the OTP failed. Technology isn’t perfect, but we’re sure you’ll give it another chance.",
    "Oops, the OTP didn’t make it out the door. Let’s blame the server for now—try resending it.",
    "The OTP didn’t make it. Maybe it got stuck in a digital traffic jam. Hit resend to give it another shot.",
    "Failed to send OTP. It happens to the best of systems. Give it another go—third time’s the charm, maybe?",
    "Sending the OTP didn’t work this time. It’s like the server pressed snooze. Wake it up by trying again!",
    "We tried sending the OTP, but it didn’t cooperate. Don’t give up—resend and show it who’s boss!",
    "Your OTP didn’t make it. Let’s give it another chance to shine—hit resend and we’ll cheer it on."
  ]

  const verifyFailedMessages = [
    "Verification failed! Don’t worry; even computers have bad days. Let’s try one more time.",
    "Oops, no luck this time. The OTP verification process must’ve been distracted. Try again!",
    "The OTP didn’t pass the vibe check. Let’s see if it’s feeling more cooperative this time.",
    "The system failed to verify the OTP. Maybe it’s just being dramatic? Try again to calm it down.",
    "Error verifying OTP. Technology is wonderful—when it works. Let’s give it one more go."
  ]
  let randIndex= Math.floor(Math.random() * invalidEmailMessages.length);
  let failedIndex = Math.floor(Math.random() * failedOtpMessages.length);
  let verifyIndex = Math.floor(Math.random() * verifyFailedMessages.length);

  const handleRequestReset = async () => {
    if (!email) {
      setError(invalidEmailMessages[randIndex]);
      return;
    }
    if (!validateEmail(email)) {
      setError(invalidEmailMessages[randIndex]);
      return;
    }
    console.log("Password reset requested for:", email);
    setError("");
    try {
      // Send OTP request to the backend API
      const response = await fetch(
        `${backendURL}/users/resetUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Ensure we're sending JSON data
          },
          body: JSON.stringify({ email }), // Send email as JSON
        }
      );
      // console.log('otp response', response.json())
      const responseData = await response.json();


      if (response.ok) {
        // Check if the response status is 200-299 (success)
        console.log("OTP sent successfully", responseData.message);
        setOtpMessage(responseData.message)
        setStep(2); // Proceed to OTP verification step
      } else {
        const errorData = await response.json(); // If the response is not successful, parse the error message
        setError(errorData.message || "Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setError(failedOtpMessages[failedIndex]);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp || otp.length !== 6) {
      setError("Please enter a valid 6-digit OTP");
      return;
    }
    console.log("OTP verified:", otp);
    setError("");
    try {
      // Send OTP verification request to the backend API
      const response = await fetch(
        `${backendURL}/users/verifyOtp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Ensure we're sending JSON data
          },
          body: JSON.stringify({ email, otp }), // Send email and OTP as JSON
        }
      );

      if (response.ok) {
        // Check if the response status is 200-299 (success)
        console.log("OTP verified successfully");
        setStep(3); // Proceed to reset password step
      } else {
        const errorData = await response.json(); // If the response is not successful, parse the error message
        console.log('errorData', errorData.message)
        setError(errorData.message || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setError(verifyFailedMessages[verifyIndex]);
    }
  };
  const navigate = useNavigate();
  const handleResetPassword = async () => {
    try {
      // Input validation
      if (!newPassword || !confirmPassword) {
        setError("Please fill in all fields");
        return;
      }

      if (newPassword !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }
      if (newPassword.length < 8 || !/[!@#$%^&*]/.test(newPassword)) {
        setError(
          "Password must be at least 8 characters long and include a special character"
        );
        return;
      }

      // Clear any existing errors
      setError("");

      console.log("Attempting to reset password with:", newPassword);

      // Send password reset request to the backend API using fetch
      const response = await fetch(
        `${backendURL}/users/resetPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            newPassword,
          }),
        }
      );

      // Check if the response is successful
      if (response.ok) {
        console.log("Password reset successful");
        alert("Password has been reset successfully!");
        navigate("/login");
      } else {
        // Handle error responses
        const errorData = await response.json();
        setError(
          errorData.message ||
            "Failed to reset password. Please try again later."
        );
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setError("Failed to reset password. Please try again later.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  return (
    <div className={styles.authPage}>
      <div className={styles.formContainerpr}>
        {step === 1 && (
          <div className={styles.stepContainer}>
            <div className={styles.stepHeading}>
              <h2 className={styles.heading}>Forgot Password?</h2>
              <p className={styles.subheading}>
                Enter your registered email to receive a reset link.
              </p>
            </div>
            <div className={styles.inputGroup}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles.input}
              />
              {error && <p className={styles.error}>{error}</p>}
            </div>

            <button className={styles.button} onClick={handleRequestReset}>
              Send OTP
            </button>
          </div>
        )}

        {step === 2 && (
          <div className={styles.stepContainer}>
            <div className={styles.stepHeading}>
              <h2 className={styles.heading}>Verify OTP</h2>
              <p className={styles.subheading}>
                {otpMessage || 'Enter the 6-digit OTP sent to your email'}
              </p>
            </div>
            <div className={styles.inputGroup}>
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className={styles.input}
              />
              {error && <p className={styles.error}>{error}</p>}
            </div>
            <button className={styles.button} onClick={handleVerifyOtp}>
              Verify OTP
            </button>
          </div>
        )}

        {step === 3 && (
          <div className={styles.stepContainer}>
            <div className={styles.stepHeading}>
              <h2 className={styles.heading}>Reset Password</h2>
              <p className={styles.subheading}>
                Enter and confirm your new password
              </p>
            </div>
            <div className={styles.parentInputGroup}>

              <div className={styles.inputGroup}>
                <div className={styles.passwordInput} >
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className={styles.input}
                />
                <button type="button" onClick={togglePasswordVisibility} className="passwordToggle" > {showPassword ? '🙈' : '👁️' } </button>
                </div>
                {error && <p className={styles.error}>{error}</p>}
              </div>
              <div className={styles.inputGroup}>
                <div className={styles.passwordInput} >
                <input
                  type={ showConfirmPassword ? "text" : "password" }
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={styles.input}
                />
                <button type="button" onClick={toggleConfirmPasswordVisibility} className="passwordToggle" > {showConfirmPassword ? '🙈' : '👁️' } </button>
                </div>
                {error && <p className={styles.error}>{error}</p>}
              </div>
            </div>
            <button className={styles.button} onClick={handleResetPassword}>
              Reset Password
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordRecovery;
