import React, { useEffect, useState } from "react";
// import axios from 'axios';
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";


const SignupForm: React.FC = () => {
  const { languages,fetchLanguages } = useUser();
  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    username:"",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpStep, setIsOtpStep] = useState(false); // Tracks OTP step
  const [isSSOSignup, setIsSSOSignup] = useState(false); // Tracks if SSO signup
  const [isLanguageSelection, setIsLanguageSelection] = useState(false); // Tracks language selection step
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [knownLanguages, setKnownLanguages] = useState<string[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  // const [languages, setLanguages] = useState<string[]>([]);
  const [emailFromSSO, setEmailFromSSO] = useState(""); // Stores email from SSO login
  const [sentMessage, setSentMessage] = useState("")
  // let backendUrl = "http://localhost:5000";
  let backendUrl = "https://totle-webisite-backend.onrender.com";
  const navigate = useNavigate();

  // const languages = ["English", "Spanish", "French", "German", "Mandarin", "Telugu", "Kannada", "Tamil", "Hindi", "Gujarati"];
  // Fetch languages from the backend
  useEffect(() => {
    if (languages.length === 0) {
      fetchLanguages();
    }
    const queryParams = new URLSearchParams(window.location.search);
    const isNewUserParam = queryParams.get("isNewUser");
    const emailFromSSO = queryParams.get("email");

    if (isNewUserParam === "true") {
      setEmailFromSSO(emailFromSSO || "");
      setIsSSOSignup(true);
      setIsLanguageSelection(true); // Directly show language selection if `isNewUser=true`
    }
  }, [languages, fetchLanguages]);

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.email && !formData.mobile) {
      newErrors.contact = "Either Email or Mobile number is required.";
    }
    if(!formData.username){
      newErrors.username="Username is required.";
    }
    if (!formData.password) {
      newErrors.password = "Password is required.";
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }
    return newErrors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      console.log('email:', formData.email, 'password:', formData.password);
      const response = await fetch(`${backendUrl}/users/signup`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
              email: formData.email,
              password: formData.password
          }),
      });
      console.log('response:', response);

      const data = await response.json();
      console.log('data:', data);
      if (data.error==='false') {
          console.log('data message:', data.message);
          setSentMessage(data.message);
          setIsOtpStep(true);
      } else {
          console.log("OTP sent successfully.");
          setSentMessage(data.message);
          setIsOtpStep(true); // Proceed to OTP verification step
      }
    } catch (error) {
        console.error("Error during signup:", error);
    }

    if (!isSSOSignup) {
      console.log("Sending OTP to:", formData.email || formData.mobile);
      setIsOtpStep(true); // Show OTP step
    } else {
      console.log("SSO Signup successful, proceeding to language selection...");
      setIsLanguageSelection(true);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const response = await fetch(`${backendUrl}/users/signup/verifyOtp`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
              email: formData.email, // Use the email already entered in Step 1
              otp,
              password: formData.password,
              username: formData.username,
          }),
      });

      const data = await response.json();
      if (data.error) {
          alert(data.message);
      } else {
          console.log("OTP verified successfully.");
          setIsOtpStep(false);
          navigate('/platform') // Proceed to language selection
      }
  } catch (error) {
      console.error("Error during OTP verification:", error);
  }
  };

  const handleSSOSignup = async (provider: string) => {
    // console.log(`SSO Signup initiated with ${provider}.`);
    //   if (provider === "Google") {
    //     window.location.href = `${backendUrl}/auth/google`;
    //   } else if (provider === "Meta") {
    //     window.location.href = `${backendUrl}/auth/meta`;
    //   } else if (provider === "X") {
    //     window.location.href = `${backendUrl}/auth/x`;
    //   }  
    try{
      if(provider === "Google"){
        window.location.href = `${backendUrl}/auth/google`;
      }
    }catch(error){
      console.error(error);
    }
  };

  const handleLanguageSelectionSubmit = async() => {
    try {
      const response = await fetch(`${backendUrl}/users/signup/complete`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
              preferredLanguage,
              knownLanguages,
              email: formData.email,
          }),
      });

      const data = await response.json();
      if (data.error) {
          alert(data.message);
      } else {
          console.log("Signup complete! Redirecting...");
          if (isSSOSignup) {
            console.log("Redirecting to teach page...");
            navigate("/teach"); // Redirect to teach page for SSO
          } else {
            console.log("Redirecting to login page...");
            navigate("/auth/login");
            // Redirect to login page for traditional signup
          }
      }
    } catch (error) {
        console.error("Error during language selection submission:", error);
    }
  };

  // Mock SSO Login Function
  /*const mockSSOLogin = async (provider: string) => {
    console.log(`Simulating SSO login with ${provider}...`);
    return new Promise<{ isNewUser: boolean; email?: string }>((resolve) => {
      setTimeout(() => {
        const isNewUser = Math.random() > 0.5; // Randomly decide if the user is new
        const email = isNewUser ? "new_user@example.com" : "existing_user@example.com";
        resolve({ isNewUser, email });
      }, 1000); // Simulate 1-second delay
    });
  };
  */
  const hasErrors = Object.keys(errors).length > 0;
  return (
    <div className="px-4 sm:px-7 md:px-8 " >
      {/* Step 1: Signup Form */}
      {!isOtpStep && !isLanguageSelection && (
        <>
          <h2 className={`text-2xl font-bold text-gray-800 mb-4 sm:mt-1 ${hasErrors ? "lg:mt-64 20%" : "lg:mt-40 10%"} text-center`}>Sign Up</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Mobile</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            {errors.contact && <p className="text-red-500 text-sm">{errors.contact}</p>}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Username</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            {errors.username && <p className="text-red-500 text-sm">{errors.username}</p>}
            <div className="mb-4 relative">
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <div className="relative mt-1">
              <input
                type={showPassword?"text":"password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className=" block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
              <div
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <span className="h-5 w-5 text-gray-500">👁</span>
                  ) : (
                    <span className="h-5 w-5 text-gray-500">🙈</span>
                  )}
              </div>
              </div>
              {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
            </div>
            <div className="mb-4 relative">
              <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
              <div className="relative mt1">
              <input
                type={showConfirmPassword?"text":"password"}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
              <div
                  className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <span className="h-5 w-5 text-gray-500">👁</span>
                  ) : (
                    <span className="h-5 w-5 text-gray-500">🙈</span>
                  )}
              </div>
              </div>
              {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword}</p>}
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500"
            >
              Sign Up
            </button>
          </form>

          {/* SSO Buttons */}
          <div className="mt-6 text-center">
            <p className="text-gray-500">Or Sign Up With:</p>
            <div className="flex justify-center space-x-4 mb-4 mt-4">
              <button
                onClick={() => handleSSOSignup("Google")}
                className="bg-gray-200 p-2 rounded-md hover:bg-gray-300"
              >
                Google
              </button>
              <button
                onClick={() => handleSSOSignup("Meta")}
                className="bg-gray-200 p-2 rounded-md hover:bg-gray-300"
              >
                Meta
              </button>
              <button
                onClick={() => handleSSOSignup("X")}
                className="bg-gray-200 p-2 rounded-md hover:bg-gray-300"
              >
                X
              </button>
            </div>
          </div>
        </>
      )}

      {/* Step 2: OTP Verification */}
      {!isSSOSignup && isOtpStep && (
        <div className="top-20">
          <h3 className="text-lg font-bold mb-4 text-center">Verify OTP</h3>
          <p className="text-gray-600 mb-4">
            {sentMessage || (<>OTP sent to <strong>${emailFromSSO || formData.email || formData.mobile}</strong></>)}
          </p>
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 mb-4"
          />
          <div className="flex justify-center w-full">
          <button
            onClick={handleOtpSubmit}
            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
          >
            Verify
          </button>
          </div>
        </div>
      )}

      {/* Step 3: Language Selection */}
      {isLanguageSelection && (
        <div className="top-20">
          <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">Language Selection</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Preferred Language</label>
            <select
              value={preferredLanguage}
              onChange={(e) => setPreferredLanguage(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
            >
              {/* <option value="">Select a language</option> */}
              {languages.map((lang) => (
                <option key={lang} value={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Known Languages</label>
            <div className="mt-2 h-48 overflow-y-auto border border-gray-300 rounded-md p-2">
              {languages.map((lang) => (
                <div key={lang} className="flex items-center">
                  <input
                    type="checkbox"
                    id={lang}
                    value={lang}
                    checked={knownLanguages.includes(lang)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setKnownLanguages([...knownLanguages, lang]);
                      } else {
                        setKnownLanguages(knownLanguages.filter((l) => l !== lang));
                      }
                    }}
                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor={lang} className="ml-2 block text-sm text-gray-700">
                    {lang}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={handleLanguageSelectionSubmit}
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500"
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default SignupForm;
