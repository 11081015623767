import React from "react";
import {  Routes, Route, useLocation } from "react-router-dom";
import AuthForm from './components/Auth/AuthForm';
import NotFoundPage from "../src/pages/NotFoundPage";
import PasswordRecovery from "./components/Auth/PasswordRecovery";
import PlatformPage from "./pages/PlatformPage/PlatformPage";
import MarketplacePage from "./pages/marketplace/MarketplacePage";
import TeachPage from "./pages/TeachingPlatform/TeachPage";
import LearnPage from "./pages/LearnPlatform/LearnPage";
import EndeavourPage from "./pages/EndeavourPlatform/EndeavourPage";
import Profile from "./components/Profile/Profile";
import Navbar from './pages/Navbar/Navbar'; 
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import VisionMission from './pages/Vision/Vision';
import Testimonials from './pages/TestimonialsSection/TestimonialsSection'; 
import Product from './pages/Product/Product';
import Team from './pages/Team/Team';
import Blog from './pages/Blog/Blog';
import Careers from './pages/Careers/Careers';
import Investors from './pages/Investors/Investors';
import Contact from './pages/Contact/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';

function App() {

  const location = useLocation(); // Access the current route
  const noNavbarRoutes = ['/auth', '/forgot-password', '/login', '/signup']; // Routes without Navbar

  return (
    <>
     {!noNavbarRoutes.includes(location.pathname) && <Navbar />} 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/vision" element={<VisionMission />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/product" element={<Product />} />
        <Route path="/team" element={<Team />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/auth" element={<AuthForm />} />
        <Route path='/forgot-password' element={<PasswordRecovery />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/platform" element={<PlatformPage />} />
        <Route path="/marketplace" element={<MarketplacePage />} />
        <Route path="/teach/*" element={<TeachPage />} />
        <Route path="/learn/*" element={<LearnPage />} />
        <Route path="/endeavour/*" element={<EndeavourPage />} />
      </Routes>
    </>

  );
}

export default App;
