import React from "react";
import { Routes, Route } from "react-router-dom";
import EndeavourPlatform from "../../components/EndeavourPlatform/EndeavourPlatform";
import Dashboard from "../../components/EndeavourPlatform/Dashboard/Dashboard";
import Competitions from "../../components/EndeavourPlatform/Competitions/Competitions";
import Projects from "../../components/EndeavourPlatform/Projects/Projects";
import PortfolioBuilding from "../../components/EndeavourPlatform/PortfolioBuilding/PortfolioBuilding";
import LegalCompliance from "../../components/EndeavourPlatform/LegalCompliance/LegalCompliance";

const EndeavourPage: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<EndeavourPlatform />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="competitions" element={<Competitions />} />
        <Route path="projects" element={<Projects />} />
        <Route path="portfolio-building" element={<PortfolioBuilding />} />
        <Route path="legal-compliance" element={<LegalCompliance />} />
      </Route>
    </Routes>
  );
};

export default EndeavourPage;
