import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import AOS from 'aos'; // For animations on scroll
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare } from 'react-icons/fa'; // Icons for social media
import 'aos/dist/aos.css';
import { NavLink, useNavigate } from 'react-router-dom';

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const navigate = useNavigate();

  const handleLinkClick = () => {
    navigate('/contact')
    window.scrollTo(0, 0);
  }

  return (
    <div >
      {/* Hero Section */}
      <section className="hero-bg bg-cover bg-center relative min-h-screen flex items-center justify-center parallax" style={{ backgroundImage: `url('/images/about-us.jpg')` }}>
        <div className="overlay bg-black bg-opacity-50 absolute inset-0"></div>
        <div className="text-center relative z-10" data-aos="fade-up">
          <h1 className="text-6xl font-extrabold text-white">About TOTLE</h1>
          <p className="text-xl text-gray-300 mt-4">Redefining Education for All</p>
        </div>
      </section>

      <section className="py-20 bg-gray-100">
        <div className="container mx-auto text-center" data-aos="fade-up">
          <h2 className="text-4xl font-bold mb-6 text-tangering">Who We Are</h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            TOTLE is more than a platform—it’s a revolution in education. Our mission is to empower individuals by making learning accessible, personalized, and cost-effective. Combining cutting-edge technology with the essence of human connection, TOTLE provides a global platform where learning is driven by curiosity, not constraints.
          </p>
        </div>
      </section>


      {/* Founding Story */}
      <section className="py-20 bg-white px-1 sm:px-1">
        <div className="container mx-auto text-center" data-aos="fade-up">
          <h2 className="text-4xl font-bold mb-6 text-tangering">Our Founding Story</h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            TOTLE was born from the desire to bridge gaps in education. Recognizing that traditional systems often exclude or overwhelm, our founders—visionaries from education and technology—envisioned a platform that empowers both learners and educators. By leveraging AI and community-driven support, TOTLE offers an inclusive space where every question matters, and every learner can thrive.
          </p>
        </div>
      </section>

      {/* Mission Statement */}
      <section className="py-20 bg-gray-100 px-1">
        <div className="container mx-auto text-center" data-aos="fade-up">
          <h2 className="text-4xl font-bold mb-6 text-tangering">Our Vision</h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-6">
            Our vision is to create a world where learning is universal, equitable, and empowering. Education should not be limited by geography, financial constraints, or lack of resources. TOTLE strives to democratize education by providing flexible, topic-specific learning, allowing every individual to achieve their goals.
          </p>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            We aim to foster a global community where knowledge-sharing and growth are not just encouraged but celebrated.
          </p>
        </div>
      </section>


      <section
        className="py-20 relative"
        style={{
          background: "linear-gradient(to right,rgb(71, 181, 255) 0%,rgb(73, 225, 252) 100%)",
          backdropFilter: "blur(10px)",
        }}
      >  <div className="container mx-auto text-center" data-aos="fade-up">
          <h2 className="text-4xl font-bold mb-6 text-white">Our Core Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-3">
            <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl font-semibold mb-2">Inclusivity</h3>
              <p className="text-gray-600">
                We believe in education for everyone, breaking barriers to make learning accessible worldwide.
              </p>
            </div>
            <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl font-semibold mb-2">Empowerment</h3>
              <p className="text-gray-600">
                TOTLE empowers learners to become educators, fostering a community of shared knowledge and growth.
              </p>
            </div>
            <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl font-semibold mb-2">Innovation</h3>
              <p className="text-gray-600">
                We integrate technology to complement human expertise, creating personalized and impactful learning experiences.
              </p>
            </div>
            <div className="p-6 bg-gray-100 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl font-semibold mb-2">Integrity</h3>
              <p className="text-gray-600">
                Honesty and transparency are the cornerstones of everything we do, ensuring trust with our community.
              </p>
            </div>
          </div>
        </div>
      </section>
      <footer className="bg-gray-900 text-gray-400 py-8 px-8 mt-14 flex flex-col items-center justify-between gap-2">
              <div className="text-center">
                <p className="mb-2">&copy; {new Date().getFullYear()} TOTLE. All rights reserved.</p>
                <div className="flex justify-center space-x-6">
                  <NavLink to='/privacy-policy' onClick={handleLinkClick} className="hover:text-white transition">Privacy Policy</NavLink>
                  <NavLink to='/contact' onClick={handleLinkClick} className="hover:text-white transition">Contact Us</NavLink>
                </div>
              </div>
              <div className="text-center">
                <h4 className='mb-2'>Follow us on</h4>
                <ul className='flex gap-4 items-center justify-center list-none'>
                  <li>
                    <a href="http://www.linkedin.com/in/totle-edu-3b0792347" target="_blank" rel="noreferrer">
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/TotleEdu" target="_blank" rel="noreferrer">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/totle_edu/?next=%2F" target="_blank" rel="noreferrer">
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61572098313677" target="_blank" rel="noreferrer">
                      <FaFacebookSquare />
                    </a>
                  </li>
                </ul>
              </div>
            </footer>
    </div>
  );
};

export default AboutUs;
