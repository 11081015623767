import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaBookOpen, FaChalkboardTeacher, FaAward, FaChartLine } from 'react-icons/fa';
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare, FaGlobe } from 'react-icons/fa'; // Icons for social media
import { NavLink, useNavigate } from 'react-router-dom';


const Product = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const navigate = useNavigate();


  const handleLinkClick = () => {
    navigate('/contact')
    window.scrollTo(0, 0);
  }

  return (
    <>
      {/* Hero Section */}
      <section
        className="relative min-h-screen flex items-center justify-center text-center bg-cover bg-fixed"
        style={{
          backgroundImage: "url('/images/product-hero.jpg')", // Replace with your image
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-white px-6">
          <h1
            className="text-5xl md:text-6xl font-bold bg-gradient-to-r from-tangering to-orange-500 text-transparent bg-clip-text mb-6"
            data-aos="fade-up"
          >
            Discover TOTLE’s Revolution in Education
          </h1>
          <p
            className="max-w-3xl mx-auto text-lg md:text-xl"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            TOTLE empowers learners and educators alike by offering personalized, AI-driven educational experiences that adapt to each user’s unique needs. Join us in transforming education for everyone, everywhere.
          </p>
        </div>
      </section>

      {/* Key Features Section */}
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 px-6 md:px-12 py-20 bg-gradient-to-r from-orange-50 via-white to-orange-50 dark:bg-gradient-to-r dark:from-gray-800 dark:via-gray-900 dark:to-gray-800">
  {[
    {
      icon: <FaChalkboardTeacher className="text-6xl text-green-500 mb-4" />,
      title: 'Teach Platform',
      description:
        'Empowering educators with clear progression paths and scalable opportunities, from Bridger to Legend. A hub for skill development and career growth.',
    },
    {
      icon: <FaBookOpen className="text-6xl text-blue-500 mb-4" />,
      title: 'Learn Platform',
      description:
        'Providing personalized, pay-per-topic sessions for students. Flexible learning, transparent feedback, and tailored teacher expertise ensure impactful results.',
    },
    {
      icon: <FaAward className="text-6xl text-yellow-500 mb-4" />,
      title: 'Endeavour Platform',
      description:
        'Where knowledge meets achievement. Students engage in competitions and projects that showcase their skills and foster real-world application.',
    },
    {
      icon: <FaChartLine className="text-6xl text-purple-500 mb-4" />,
      title: 'Marketplace',
      description:
        'A real-time insight hub for teachers to identify high-demand topics, optimize teaching focus, and maximize earning potential.',
    },
  ].map((platform, index) => (
    <div
      key={index}
      className="flex flex-col items-center group bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
      data-aos="fade-up"
      data-aos-delay={400 + index * 100}
    >
      {platform.icon}
      <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">
        {platform.title}
      </h3>
      <p className="text-gray-600 dark:text-gray-300 text-center">
        {platform.description}
      </p>
    </div>
  ))}
</section>



      {/* User Journey Section */}
      <section
  className="py-20 relative text-white bg-fixed bg-cover"
  style={{
    backgroundImage: "url('/images/user-journey-bg.jpg')", // Replace with your image
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }}
>
  <div className="absolute inset-0 bg-black bg-opacity-60"></div>
  <div className="relative z-10 container mx-auto px-6">
    <h2
      className="text-4xl font-bold text-center mb-12"
      data-aos="fade-up"
    >
      The Journey of a TOTLE User
    </h2>
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
      {[
        {
          step: 'Step 1',
          title: 'Discover Your Starting Point',
          description:
            'Begin your journey by identifying your goals and exploring the free or premium pathways TOTLE offers.',
        },
        {
          step: 'Step 2',
          title: 'AI-Powered Customization',
          description:
            'Leverage TOTLE’s advanced AI to create a dynamic and tailored learning path that evolves with you.',
        },
        {
          step: 'Step 3',
          title: 'Learn in Interactive Sessions',
          description:
            'Engage in personalized live sessions with Bridgers or Experts, designed to cater to your unique learning needs.',
        },
        {
          step: 'Step 4',
          title: 'Track Your Progress',
          description:
            'Monitor your growth through detailed progress reports, real-time feedback, and milestone achievements.',
        },
        {
          step: 'Step 5',
          title: 'Compete and Achieve',
          description:
            'Participate in competitions and projects to showcase your knowledge and gain practical skills.',
        },
        {
          step: 'Step 6',
          title: 'Celebrate and Share',
          description:
            'Celebrate your achievements, share success stories, and unlock future opportunities with TOTLE’s support.',
        },
      ].map((journey, index) => (
        <div
          key={index}
          className="bg-gradient-to-r from-orange-50 via-white to-orange-50 dark:from-gray-700 dark:via-gray-800 dark:to-gray-700 p-8 rounded-lg shadow-lg flex flex-col justify-between text-center"
          data-aos="fade-up"
          data-aos-delay={300 + index * 100}
        >
          <h3 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
            {journey.step}: {journey.title}
          </h3>
          <p className="text-gray-600 dark:text-gray-300">{journey.description}</p>
        </div>
      ))}
    </div>
  </div>
</section>

      <section className="pt-[5rem] pb-[2rem] bg-gray-100">
      <h2 className="text-4xl font-bold mb-6 text-tangering">What Makes US Different?</h2>
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center px-2">
        

          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            {/* <svg className="mx-auto mb-4 w-12 h-12 text-tangering" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c2.28 0 4-1.72 4-4s-1.72-4-4-4-4 1.72-4 4 1.72 4 4 4z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 14c0 1.88 1.4 3.57 3.47 3.96A2.5 2.5 0 0113 20h1v2H7v-2h1a2.5 2.5 0 011.53-2.04C9.6 17.57 8 15.88 8 14zm9.62 1.03l-1.27-.6a2.5 2.5 0 010-4.86l1.27-.6a5 5 0 010 6.06zM15 10l.89-.45a2.5 2.5 0 000-4.56L15 4.54a5 5 0 000 5.92zM13.76 17a5 5 0 000-2.75l1.27-.6a2.5 2.5 0 010 4.86l-1.27-.6z" />
            </svg> */}
            <FaGlobe className="mx-auto mb-4 w-12 h-12 text-tangering" />
            <h3 className="text-xl font-semibold mb-2">Universal Learning</h3>
            <p className="text-gray-600">There exists nothing that cannot be taught.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            {/* <svg className="mx-auto mb-4 w-12 h-12 text-tangering" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7H7v6h6V7z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6v6H9V7zM4 11h2v2H4v-2zm12 0h2v2h-2v-2zm-6 8v2H4v-2h2v-4H4v-2h4v2H6v4h4zM16 15h4v4h-4v-4zM4 11h2v2H4v-2zm4 0h6v2H8v-2z" />
            </svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto mb-4 w-12 h-12 text-tangering" viewBox="-0.5 -0.5 16 16"
              id="Route-Fill--Streamline-Mingcute-Fill"
              height="16" width="16"><desc>Route Fill Streamline Icon: https://streamlinehq.com</desc><g fill="none" fill-rule="nonzero">
                <path d="M15 0v15H0V0h15ZM7.870625 14.536249999999999l-0.006874999999999999 0.00125 -0.044375 0.021875000000000002 -0.0125 0.0025 -0.00875 -0.0025 -0.044375 -0.021875000000000002c-0.00625 -0.0025 -0.011875 -0.000625 -0.015 0.003125l-0.0025 0.00625 -0.010625 0.2675 0.003125 0.0125 0.00625 0.008125 0.065 0.04625 0.009375 0.0025 0.0075 -0.0025 0.065 -0.04625 0.0075 -0.01 0.0025 -0.010625 -0.010625 -0.266875c-0.00125 -0.00625 -0.005625 -0.010625 -0.010625 -0.01125Zm0.16562500000000002 -0.07062500000000001 -0.008125 0.00125 -0.115625 0.058124999999999996 -0.00625 0.00625 -0.001875 0.006874999999999999 0.01125 0.26875 0.003125 0.0075 0.005 0.004375 0.12562500000000001 0.058124999999999996c0.0075 0.0025 0.014374999999999999 0 0.018125000000000002 -0.005l0.0025 -0.00875 -0.02125 -0.38375c-0.001875 -0.0075 -0.00625 -0.0125 -0.0125 -0.013749999999999998Zm-0.44687499999999997 0.00125a0.014374999999999999 0.014374999999999999 0 0 0 -0.016875 0.00375l-0.00375 0.00875 -0.02125 0.38375c0 0.0075 0.004375 0.0125 0.010625 0.015l0.009375 -0.00125 0.12562500000000001 -0.058124999999999996 0.00625 -0.005 0.0025 -0.006874999999999999 0.010625 -0.26875 -0.001875 -0.0075 -0.00625 -0.00625 -0.11499999999999999 -0.057499999999999996Z" stroke-width="1"></path><path fill="#ff6347" d="M11.25 10a1.875 1.875 0 1 1 0 3.75 1.875 1.875 0 0 1 0 -3.75ZM9.6875 2.5a2.8125 2.8125 0 1 1 0 5.625h-4.375a1.5625 1.5625 0 0 0 0 3.125H8.125a0.625 0.625 0 1 1 0 1.25H5.3125a2.8125 2.8125 0 1 1 0 -5.625h4.375a1.5625 1.5625 0 0 0 0 -3.125H6.875a0.625 0.625 0 1 1 0 -1.25h2.8125ZM3.75 1.25a1.875 1.875 0 1 1 0 3.75 1.875 1.875 0 0 1 0 -3.75Z" stroke-width="1"></path>
              </g>
            </svg>
            <h3 className="text-xl font-semibold mb-2">Take control of your career</h3>
            <p className="text-gray-600">Self reliance is not a virtue. It is imperative.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            {/* <svg className="mx-auto mb-4 w-12 h-12 text-tangering" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16.92 12.1l-.96-3.72m1.2 4.62h-3.9m4.62 1.92l-2.7 3.6m0 0l-2.7-3.6m0 0L9.96 9.12M7.8 10.32L8.76 6.6M5.52 10.8h3.9m-.96-1.92l2.7-3.6" />
            </svg> */}
            <img src="/images/follow.png" alt="Human centric" className="mx-auto mb-4 w-12 h-12 text-tangering bg-transparent" />
            <h3 className="text-xl font-semibold mb-2">Human-Centric Teaching</h3>
            <p className="text-gray-600">Preserving the human element in education.</p>
          </div>
        </div>
      </section>  


      <footer className="bg-gray-900 text-gray-400 py-8 px-8 mt-14 flex flex-col items-center justify-between gap-2">
              <div className="text-center">
                <p className="mb-2">&copy; {new Date().getFullYear()} TOTLE. All rights reserved.</p>
                <div className="flex justify-center space-x-6">
                  <NavLink to='/privacy-policy' onClick={handleLinkClick} className="hover:text-white transition">Privacy Policy</NavLink>
                  <NavLink to='/contact' onClick={handleLinkClick} className="hover:text-white transition">Contact Us</NavLink>
                </div>
              </div>
              <div className="text-center">
                <h4 className='mb-2'>Follow us on</h4>
                <ul className='flex gap-4 items-center justify-center list-none'>
                  <li>
                    <a href="http://www.linkedin.com/in/totle-edu-3b0792347" target="_blank" rel="noreferrer">
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/TotleEdu" target="_blank" rel="noreferrer">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/totle_edu/?next=%2F" target="_blank" rel="noreferrer">
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61572098313677" target="_blank" rel="noreferrer">
                      <FaFacebookSquare />
                    </a>
                  </li>
                </ul>
              </div>
            </footer>
    </>
  );
};

export default Product;
