import React from "react";


const PrivacyPolicy = () => {

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-6 lg:px-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800">Privacy Policy</h1>

        </div>

        <div className="bg-white p-8 rounded-xl shadow-xl">
          {/* 1. Information We Collect */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              1. Information We Collect
            </h2>
            <p className="text-gray-700 mb-4">
              We collect the following types of information from users:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>
                <strong>Personal Information:</strong> Name, email address,
                phone number, payment details, demographic data.
              </li>
              <li>
                <strong>Usage Data:</strong> Pages visited, session durations,
                interaction data, quiz/competition responses.
              </li>
              <li>
                <strong>Device and Technical Information:</strong> Device type,
                IP address, cookies, and tracking data.
              </li>
            </ul>
          </section>

          {/* 2. How We Use Your Information */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              2. How We Use Your Information
            </h2>
            <p className="text-gray-700 mb-4">
              We use the information collected to:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>
                <strong>Provide and Enhance Services:</strong> Personalize
                learning sessions, process payments, recommend tailored
                content.
              </li>
              <li>
                <strong>Analytics and Improvements:</strong> Optimize platform
                features, improve marketing campaigns, and develop new content.
              </li>
              <li>
                <strong>Compliance and Security:</strong> Prevent unauthorized
                access, verify identities, and meet legal requirements.
              </li>
            </ul>
          </section>

          {/* 3. Sharing Your Information */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              3. Sharing Your Information
            </h2>
            <p className="text-gray-700 mb-4">
              We do not sell or rent your data. We share information only in the
              following cases:
            </p>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>
                <strong>With Your Consent:</strong> When explicitly agreed by
                you.
              </li>
              <li>
                <strong>Service Providers:</strong> Trusted partners under strict
                confidentiality agreements.
              </li>
              <li>
                <strong>Legal Obligations:</strong> To comply with legal
                requirements or court orders.
              </li>
            </ul>
          </section>

          {/* 4. Cookies and Tracking Technologies */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              4. Cookies and Tracking Technologies
            </h2>
            <p className="text-gray-700">
              Cookies help us identify returning users, track user behavior, and
              run personalized campaigns. You can manage cookies via your
              browser settings.
            </p>
          </section>

          {/* 5. Data Security */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              5. Data Security
            </h2>
            <p className="text-gray-700">
              We implement industry-standard security measures, including
              encryption, regular audits, and secure data centers compliant with
              ISO 27001 standards.
            </p>
          </section>

          {/* 6. Your Rights */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              6. Your Rights
            </h2>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>Access and portability of your data.</li>
              <li>Request corrections to inaccuracies.</li>
              <li>Deletion of your data, subject to applicable laws.</li>
              <li>Opt-out of marketing communications.</li>
            </ul>
          </section>

          {/* 7. Children's Privacy */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              7. Children’s Privacy
            </h2>
            <p className="text-gray-700">
              For users under the age of 13, we require consent from a parent or
              guardian to create an account.
            </p>
          </section>

          {/* 8. Retention Policy */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              8. Retention Policy
            </h2>
            <p className="text-gray-700">
              We retain personal data for as long as your account is active or
              as necessary to comply with legal obligations.
            </p>
          </section>

          {/* 9. Changes to This Policy */}
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              9. Changes to This Policy
            </h2>
            <p className="text-gray-700">
              We may update this Privacy Policy periodically. We encourage you
              to review this page regularly for any updates.
            </p>
          </section>

          {/* 10. Contact Us */}
          <section>
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              10. Contact Us
            </h2>
            <p className="text-gray-700">
              For questions about this Privacy Policy, contact us at:
            </p>
            <p className="text-gray-700">
              <strong>Email:</strong> support@totle.com
            </p>
            <p className="text-gray-700">
              <strong>Phone:</strong> +91 9381281438
            </p>
          </section>
        </div>
      </div>

    </section>
  );
};

export default PrivacyPolicy;
