import React, { useState } from "react";
import styles from "./SessionManagement.module.css";

// Generate overlapping 2-hour slots
const generateTimeSlots = (duration = 2): string[] => {
  const slots: string[] = [];
  for (let hour = 0; hour <= 24 - duration; hour++) {
    const start = `${hour.toString().padStart(2, "0")}:00`;
    const end = `${(hour + duration).toString().padStart(2, "0")}:00`;
    slots.push(`${start} - ${end}`);
  }
  return slots;
};

// Generate next 7 days starting from tomorrow
const generateNext7Days = (): Date[] => {
  const days: Date[] = [];
  const today = new Date();
  for (let i = 1; i <= 7; i++) {
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + i);
    days.push(nextDay);
  }
  return days;
};

const SessionManagement: React.FC = () => {
  const timeSlots = generateTimeSlots(2); // Overlapping 2-hour slots
  const [next7Days] = useState<Date[]>(generateNext7Days());
  const [availability, setAvailability] = useState(
    Array(7)
      .fill(null)
      .map(() => Array(timeSlots.length).fill(false)) // Initialize all slots as unavailable
  );

  // Toggle availability for a specific slot
  const handleToggleSlot = (dayIndex: number, slotIndex: number) => {
    setAvailability((prev) =>
      prev.map((day, dIdx) =>
        dIdx === dayIndex
          ? day.map((slot, sIdx) => (sIdx === slotIndex ? !slot : slot))
          : day
      )
    );
  };

  // Apply the first day's availability to all days
  const applyToAllDays = () => {
    const firstDayAvailability = availability[0];
    setAvailability(Array(7).fill(firstDayAvailability));
  };

  // Save changes (dummy for now)
  const handleSaveChanges = () => {
    console.log("Availability Saved:", availability);
    console.log("Dates:", next7Days.map((date) => date.toDateString()));
    alert("Availability saved successfully!");
  };

  // Reset all availability
  const handleReset = () => {
    setAvailability(
      Array(7)
        .fill(null)
        .map(() => Array(timeSlots.length).fill(false))
    );
  };

  return (
    <div className={styles.sessionManagement}>
      <h2 className={styles.heading}>Session Management</h2>
      <p className={styles.description}>
        Update your availability for 2-hour sessions for the next 7 days starting tomorrow.
        Learners will see your updated availability when booking sessions.
      </p>

      <div className={styles.schedule}>
        {next7Days.map((date, dayIndex) => (
          <div key={date.toDateString()} className={styles.day}>
            <h3 className={styles.dayHeading}>
              {date.toLocaleDateString(undefined, {
                weekday: "long",
                month: "short",
                day: "numeric",
              })}
            </h3>
            <div className={styles.timeSlots}>
              {timeSlots.map((slot, slotIndex) => (
                <div
                  key={slot}
                  className={`${styles.timeSlot} ${
                    availability[dayIndex][slotIndex] ? styles.available : ""
                  }`}
                  onClick={() => handleToggleSlot(dayIndex, slotIndex)}
                >
                  {slot}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.actions}>
        <button className={styles.saveButton} onClick={handleSaveChanges}>
          Save Changes
        </button>
        <button className={styles.resetButton} onClick={handleReset}>
          Reset
        </button>
        <button className={styles.applyButton} onClick={applyToAllDays}>
          Apply to All Days
        </button>
      </div>
    </div>
  );
};

export default SessionManagement;
