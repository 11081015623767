import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare } from 'react-icons/fa'; // Icons for social media
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const navigate = useNavigate();
  const handleLinkClick = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  };

  const [selectedTab, setSelectedTab] = useState('Academics');
  const catalogues = {
    Academics: {
      image: '/images/catalogues/academics.jpg',
      description: 'Expand your knowledge base with the best academic resources.',
    },
    Examprep: {
      image: '/images/catalogues/examprep.jpg',
      description: 'Prepare for exams with expert guidance and tools.',
    },
    Skills: {
      image: '/images/catalogues/skills.jpg',
      description: 'Enhance your career with in-demand skill-based training.',
    },
    Arts: {
      image: '/images/catalogues/arts.jpg',
      description: 'Explore your creative side with enriching arts programs.',
    },
  };


  return (
    <div className="overflow-x-hidden">
      {/* Hero Section */}
      <section
        className="relative min-h-screen flex items-center justify-end bg-cover bg-center"
        style={{
          backgroundImage: `url('/images/platform-preview.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center top',
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-right px-6 sm:px-10 lg:px-16 max-w-3xl" style={{marginTop:"10%"}}>
          <h1 className="text-5xl lg:text-6xl font-extrabold text-white" data-aos="fade-up">
            Join the TOTLE Revolution
          </h1>
          <p className="text-lg text-right lg:text-2xl text-gray-200 mt-4">
            Shaping the Future One Topic at a Time
          </p>
          <div className="mt-8">
            <NavLink
              to="/auth"
              onClick={handleLinkClick}
              className="inline-block font-bold text-center bg-white text-tangering py-2 px-6 rounded-lg hover:bg-orange-600 hover:text-white transition-all duration-300"
            >
              Apply to teach
            </NavLink>
          </div>
        </div>
      </section>

      {/* Your Path to Success */}
      <section className="py-12 bg-gray-100">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-6 text-tangering">Your Path to Success</h2>
          <p className="text-lg text-gray-700 mb-8">
            Whether you're starting as a Bridger or aiming to become a Legend, TOTLE provides
            a clear, step-by-step progression path for educators.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            {['Bridger', 'Expert', 'Master', 'Legend'].map((tier, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300"
                data-aos="fade-up"
                data-aos-delay={index * 200}
              >
                <h3 className="text-2xl font-bold text-tangering mb-4">{tier}</h3>
                <p className="text-gray-600">
                  {tier === 'Bridger'
                    ? 'Get started with minimal experience and grow.'
                    : tier === 'Expert'
                    ? 'Build a strong reputation with specialized skills.'
                    : tier === 'Master'
                    ? 'Become a trusted authority in your field.'
                    : 'Lead the way with unmatched expertise.'}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Why Choose TOTLE */}
      <section
        className="py-20 relative text-white"
        style={{
          backgroundImage: `url('/images/why-choose-background.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-75"></div>
        <div className="container mx-auto px-4 text-center relative z-10 max-w-6xl">
          <h2 className="text-5xl font-bold mb-8" data-aos="fade-up">
            Why Choose TOTLE?
          </h2>
          <p className="text-lg mb-12 text-white" data-aos="fade-up" data-aos-delay="200">
            TOTLE redefines the way we connect educators and learners by offering flexibility,
            transparency, and unmatched value.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {[
              {
                title: 'Pay-Per-Topic',
                description: 'Only pay for what you need—no long-term commitments or hidden costs.',
              },
              {
                title: 'Learner-to-Teacher Progression',
                description: 'TOTLE empowers users to grow from learners to educators.',
              },
              {
                title: 'Global Reach',
                description: 'Connect with educators and learners from around the globe.',
              },
            ].map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-center"
                data-aos="fade-up"
                data-aos-delay={index * 200 + 400}
              >
                <div className="w-24 h-24 flex items-center justify-center rounded-full bg-tangering text-white shadow-lg mb-6">
                  <span className="text-3xl font-bold">{index + 1}</span>
                </div>
                <h3 className="text-2xl font-semibold mb-4">{item.title}</h3>
                <p className="text-gray-200">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 bg-gray-100">
  <div className="container mx-auto px-6 sm:px-10 lg:px-16 flex flex-col md:flex-row items-start gap-8">
    {/* Left: Image and Description */}
    <div className="w-full md:w-2/3">
      <div
        className="relative h-96 rounded-lg overflow-hidden shadow-lg bg-cover bg-center"
        style={{
          backgroundImage: `url(${catalogues[selectedTab].image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <p className="text-white text-xl font-semibold px-6 text-center">
            {catalogues[selectedTab].description}
          </p>
        </div>
      </div>
    </div>

    {/* Right: Tabs */}
    <div className="w-full md:w-1/3 bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-2xl font-bold text-gray-800 mb-6">Catalogues</h3>
      <div className="space-y-4.5">
        {Object.keys(catalogues).map((tab) => (
          <button
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={`w-full text-left px-4 py-4 rounded-lg font-medium transition-all ${
              selectedTab === tab
                ? 'bg-tangering text-white'
                : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
            }`}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  </div>
</section>

      <section className="py-12 bg-tangering text-white text-center">
        <h2 className="text-4xl font-bold text-white mb-6">Join as an Educator</h2>
        <p className="text-lg text-white mb-8">
          Empower students, earn income, and grow your career. TOTLE is looking for passionate
          educators who want to make a difference.
        </p>
        <NavLink
          to="/auth"
          onClick={handleLinkClick}
          className="bg-white text-tangering font-bold py-3 px-6 rounded-lg hover:bg-orange-200 transition duration-300"
        >
          Apply Now
        </NavLink>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-400 py-8 px-8 mt-14 flex flex-col items-center justify-between gap-2">
        <div className="text-center">
          <p className="mb-2">&copy; {new Date().getFullYear()} TOTLE. All rights reserved.</p>
          <div className="flex justify-center space-x-6">
            <NavLink to='/privacy-policy' onClick={handleLinkClick} className="hover:text-white transition">Privacy Policy</NavLink>
            <NavLink to='/contact' onClick={handleLinkClick} className="hover:text-white transition">Contact Us</NavLink>
          </div>
        </div>
        <div className="text-center">
          <h4 className='mb-2'>Follow us on</h4>
          <ul className='flex gap-4 items-center justify-center list-none'>
            <li>
              <a href="http://www.linkedin.com/in/totle-edu-3b0792347" target="_blank" rel="noreferrer">
                <FaLinkedin />
              </a>
            </li>
            <li>
              <a href="https://x.com/TotleEdu" target="_blank" rel="noreferrer">
                <FaTwitter />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/totle_edu/?next=%2F" target="_blank" rel="noreferrer">
                <FaInstagram />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/profile.php?id=61572098313677" target="_blank" rel="noreferrer">
                <FaFacebookSquare />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
};

export default Home;
